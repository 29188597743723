import React from "react";
import { GlobalStyles } from "twin.macro";

import Layout from "../components/Layout";
import FrontReviews from "../components/FrontReviews";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import Awards from "../components/Awards";
import InnerTitleBlock from "../components/InnerTitleBlock";

const NotFoundPage = () => (
  <>
    <GlobalStyles />
    <Layout inner>
      <InnerTitleBlock header={"Error 404: Page could not be found"} />
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <Awards inner />
    </Layout>
  </>
);

export default NotFoundPage;
